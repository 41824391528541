export const GET_MASTER_GROUP = 'GET_MASTER_GROUP';
export const GET_MASTER_GROUP_SUCCESS = 'GET_MASTER_GROUP_SUCCESS';
export const GET_MASTER_GROUP_FAIL = 'GET_MASTER_GROUP_FAIL';

export const SAVE_MASTER_GROUP = 'SAVE_MASTER_GROUP';
export const SAVE_MASTER_GROUP_SUCCESS = 'SAVE_MASTER_GROUP_SUCESS';
export const SAVE_MASTER_GROUP_FAIL = 'SAVE_MASTER_GROUP_FAIL';

export const DELETE_MASTER_GROUP = 'DELETE_MASTER_GROUP';
export const DELETE_MASTER_GROUP_SUCCESS = 'DELETE_MASTER_GROUP_SUCCESS';
export const DELETE_MASTER_GROUP_FAIL = 'DELETE_MASTER_GROUP_FAIL';

export const UPDATE_MASTER_GROUP = 'UPDATE_MASTER_GROUP';
export const UPDATE_MASTER_GROUP_SUCCESS = 'UPDATE_MASTER_GROUP_SUCCESS';
export const UPDATE_MASTER_GROUP_FAIL = 'UPDATE_MASTER_GROUP_FAIL';

export const GET_DETAIL_GROUP = 'GET_DETAIL_GROUP';
export const GET_DETAIL_GROUP_SUCCESS = 'GET_DETAIL_GROUP_SUCCESS';
export const GET_DETAIL_GROUP_FAIL = 'GET_DETAIL_GROUP_FAIL';

