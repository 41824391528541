export const GET_MASTER_USER = 'GET_MASTER_USER';
export const GET_MASTER_USER_SUCCESS = 'GET_MASTER_USER_SUCCESS';
export const GET_MASTER_USER_FAIL = 'GET_MASTER_USER_FAIL';

export const SAVE_MASTER_USER = 'SAVE_MASTER_USER';
export const SAVE_MASTER_USER_SUCCESS = 'SAVE_MASTER_USER_SUCESS';
export const SAVE_MASTER_USER_FAIL = 'SAVE_MASTER_USER_FAIL';

export const DELETE_MASTER_USER = 'DELETE_MASTER_USER';
export const DELETE_MASTER_USER_SUCCESS = 'DELETE_MASTER_USER_SUCESS';
export const DELETE_MASTER_USER_FAIL = 'DELETE_MASTER_USER_FAIL';

export const UPDATE_MASTER_USER = 'UPDATE_MASTER_USER';
export const UPDATE_MASTER_USER_SUCCESS = 'UPDATE_MASTER_USER_SUCCESS';
export const UPDATE_MASTER_USER_FAIL = 'UPDATE_MASTER_USER_FAIL';

export const GET_DETAIL_USER = 'GET_DETAIL_USER';
export const GET_DETAIL_USER_SUCCESS = 'GET_DETAIL_USER_SUCCESS';
export const GET_DETAIL_USER_FAIL = 'GET_DETAIL_USER_FAIL';

export const CHANGE_PASSWORD_USER = 'CHANGE_PASSWORD_USER';
export const CHANGE_PASSWORD_USER_SUCCESS = 'CHANGE_PASSWORD_USER_SUCCESS';
export const CHANGE_PASSWORD_USER_FAIL = 'CHANGE_PASSWORD_USER_FAIL';

export const CHANGE_PHOTO_USER = 'CHANGE_PHOTO_USER';
export const CHANGE_PHOTO_USER_SUCCESS = 'CHANGE_PHOTO_USER_SUCCESS'
export const CHANGE_PHOTO_USER_FAIL = 'CHANGE_PHOTO_USER_FAIL'

