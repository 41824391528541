export const GET_OUTGOING_MAIL = 'GET_OUTGOING_MAIL';
export const GET_OUTGOING_MAIL_SUCCESS = 'GET_OUTGOING_MAIL_SUCCESS';
export const GET_OUTGOING_MAIL_FAIL = 'GET_OUTGOING_MAIL_FAIL';

export const CREATE_OUTGOING_MAIL = 'CREATE_OUTGOING_MAIL';
export const CREATE_OUTGOING_MAIL_SUCCESS = 'CREATE_OUTGOING_MAIL_SUCESS';
export const CREATE_OUTGOING_MAIL_FAIL = 'CREATE_OUTGOING_MAIL_FAIL';

export const DELETE_OUTGOING_MAIL = 'DELETE_OUTGOING_MAIL';
export const DELETE_OUTGOING_MAIL_SUCCESS = 'DELETE_OUTGOING_MAIL_SUCCESS';
export const DELETE_OUTGOING_MAIL_FAIL = 'DELETE_OUTGOING_MAIL_FAIL';

export const GET_DETAIL_OUTGOING_MAIL = 'GET_DETAIL_OUTGOING_MAIL';
export const GET_DETAIL_OUTGOING_MAIL_SUCCESS = 'GET_DETAIL_OUTGOING_MAIL_SUCCESS';
export const GET_DETAIL_OUTGOING_MAIL_FAIL = 'GET_DETAIL_OUTGOING_MAIL_FAIL';

export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAIL = 'SEARCH_USER_FAIL'

export const CREATE_DISPOSE_OUTGOING_MAIL = 'CREATE_DISPOSE_OUTGOING_MAIL';
export const CREATE_DISPOSE_OUTGOING_MAIL_SUCCESS = 'CREATE_DISPOSE_OUTGOING_MAIL_SUCCESS';
export const CREATE_DISPOSE_OUTGOING_MAIL_FAIL = 'CREATE_DISPOSE_OUTGOING_MAIL_FAIL';
