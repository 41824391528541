export const GET_MASTER_POSITION = 'GET_MASTER_POSITION';
export const GET_MASTER_POSITION_SUCCESS = 'GET_MASTER_POSITION_SUCCESS';
export const GET_MASTER_POSITION_FAIL = 'GET_MASTER_POSITION_FAIL';

export const SAVE_MASTER_POSITION = 'SAVE_MASTER_POSITION';
export const SAVE_MASTER_POSITION_SUCCESS = 'SAVE_MASTER_POSITION_SUCESS';
export const SAVE_MASTER_POSITION_FAIL = 'SAVE_MASTER_POSITION_FAIL';

export const DELETE_MASTER_POSITION = 'DELETE_MASTER_POSITION';
export const DELETE_MASTER_POSITION_SUCCESS = 'DELETE_MASTER_POSITION_SUCCESS';
export const DELETE_MASTER_POSITION_FAIL = 'DELETE_MASTER_POSITION_FAIL';

export const UPDATE_MASTER_POSITION = 'UPDATE_MASTER_POSITION';
export const UPDATE_MASTER_POSITION_SUCCESS = 'UPDATE_MASTER_POSITION_SUCCESS';
export const UPDATE_MASTER_POSITION_FAIL = 'UPDATE_MASTER_POSITION_FAIL';

export const GET_DETAIL_POSITION = 'GET_DETAIL_POSITION';
export const GET_DETAIL_POSITION_SUCCESS = 'GET_DETAIL_POSITION_SUCCESS';
export const GET_DETAIL_POSITION_FAIL = 'GET_DETAIL_POSITION_FAIL';

